import Credits from "../components/Credits"


const OverlayModel = {
  how_to:{
    header: 'How to use this resource',
    video:"https://youtu.be/B9_KroK1uEo"
  },
  welcome:{

  },
  about:{
    header: 'About',
    headerBg:'https://d1qk8s4yjmvpvz.cloudfront.net/ourcitychambers/about_bg.jpg',
    content:`
    <p>This project has been created to enable children and young people to explore the architecture, design and social history of Glasgow’s City Chambers. It aims to identify the materials, traditional skills and techniques employed in the construction and decoration of the Chambers, as well as the role the City Council plays in providing key public services to the people of Glasgow.</p>

    <p>The City Chambers is still a functioning workplace for the Council, as well as a focal building for Glasgow which welcomes visitors to the city, and hosts important events and ceremonies.</p>
    
    <p>In creating a digital replication of the City Chambers we hope this resource provides insight into the building, its purpose and its history. It also gives you an opportunity to see some of the spaces and rooms that are not usually open to the public.</p>
    
    <p>Glasgow City Heritage Trust (GCHT) worked closely with the design team at SUUM studio to create this resource. We would like to thank Glasgow City Archives for their support in researching the project and for granting permission to use images of William Young’s original architectural drawings of the City Chambers. We would like to give our heartfelt thanks to all the staff at the City Chambers and the pupils and teachers of St. Mungo’s Primary School who contributed to the making of this project. Their support and insights are much appreciated.</p>
    
    <p>GCHT works with people and organisations to help protect, repair and promote the city’s historic buildings and places. Every year we provide grants and activities to support and guide conservation and conversations around the historic built environment. To learn more about our work and the projects we have supported please visit <a href="https://www.glasgowheritage.org.uk/" target="_blank">https://www.glasgowheritage.org.uk/</a>.</p>
    
    `
  },
  resources:{
    header: 'Resources',
    headerBg:'https://d1qk8s4yjmvpvz.cloudfront.net/ourcitychambers/resources_bg.jpg',
    content:`
    <p><b>Art UK</b> - The online home for every public collection in the UK. Featuring over 300,000 artworks by over 50,000 artists. Their resources can be searched by key stage and include lesson plans and curriculum links: 
<a href="https://artuk.org/learn/learning-resources/view_as/grid/search/lr_resource:lesson-plan" target="_blank">https://artuk.org/learn/learning-resources/view_as/grid/search/lr_resource:lesson-plan</a></p>

<p><b>The Engine Shed</b> - Part of Historic Environment Scotland, they have free online teaching resources exploring Scotland’s traditional building materials: 
<a href="https://www.engineshed.scot/learning/resources-for-teachers/" target="_blank">https://www.engineshed.scot/learning/resources-for-teachers/</a></p>

<p>They also have fun activities for families:
<a href="https://www.engineshed.scot/learning/fun-for-families/" target="_blank">https://www.engineshed.scot/learning/fun-for-families/</a></p>

<p><b>Gallus Glasgow</b> - This interactive digital project explores the development of Glasgow in the Victorian period, through the eyes of Thomas Sulman, illustrator of the Bird’s Eye View of the city from 1864. The project website includes information about Glasgow’s buildings, past and present, as well as an animation following a day in the life of an average family of the time. There are also learning resources based on the characters from the animation:
<a href="https://gallusglasgow.glasgowheritage.org.uk/" target="_blank">https://gallusglasgow.glasgowheritage.org.uk/</a></p>

<p><b>Glasgow City Heritage Trust</b> - If you would enjoy learning a bit about the heritage of your local area, and helping others do the same, why not create a Kids Heritage Trail using this toolkit:
<a href="https://www.glasgowheritage.org.uk/kids-trail-toolkit/" target="_blank">https://www.glasgowheritage.org.uk/kids-trail-toolkit/</a></p>

<p><b>Glasgow Life</b> - Their schools programme features a wide range of workshops based in museums across the city, all with curriculum links:
<a href="https://www.glasgowlife.org.uk/museums/learning/schools-and-nurseries" target="_blank">https://www.glasgowlife.org.uk/museums/learning/schools-and-nurseries</a></p>

<p><b>Royal Institute of British Architects (RIBA)</b> - Their learning resources for schools use architecture and the built environment to bring subjects to life in creative ways. The resources are devised to be delivered by teachers or architecture professionals delivering workshops in school, and include teacher notes, activity guides and PowerPoint presentations:
<a href="https://www.architecture.com/education-cpd-and-careers/learning/riba-national-schools-programme/learning-resources" target="_blank">https://www.architecture.com/education-cpd-and-careers/learning/riba-national-schools-programme/learning-resources</a></p>

    `
  },
  glossary:{
    header: 'Glossary',
    headerBg:'https://d1qk8s4yjmvpvz.cloudfront.net/ourcitychambers/glossary_bg.jpg',
    content:`
    <p><b>Arch</b> - A curved opening or doorway.</p>

<p><b>Architecture</b> - The art of designing a building. It can also refer to the style of a building, for example the City Chambers is built in a Classical style of architecture.</p>

<p><b>Balconies</b> - A small porch that sticks out of a building above the ground.</p>

<p><b>Balustrade</b> - A railing found on the edge of stairs, or a balcony.</p>

<p><b>Caryatid</b> - A column in the shape of a woman used to support part of a building. Commonly used in Ancient Greece.</p>

<p><b>Columns</b> - An upright pillar used to support part of a building. There are 3 main types of column: Doric, Ionic, Corinthian. Doric are the most chunky and plain, and Corinithian are the most slender and decorated.</p>

<p><b>Cornice</b> - Decorative moulding often found around the top of a room where the wall meets the roof.</p>

<p><b>Dado</b> - The lower part of a wall above the skirting board, sometimes it’s made of plaster but often panelled wood.</p>

<p><b>Dado</b> Rail - A long thin decorative rail around waist height on a wall.</p>

<p><b>Dome</b> - A rounded roof with a base shaped like a circle.</p>

<p><b>Electroliers</b> - A fancy and elaborate fixture for holding electric bulbs. Most people would call them chandeliers but technically ‘chandelier’ means the fitting was made for candles.</p>

<p><b>Entablature</b> - A horizontal beam usually supported by columns.</p>

<p><b>Finial</b> - A decorative feature marking the top or corner of a roof.</p>

<p><b>Freestone</b> - A particular type of stone with a very fine grain so it can be cut in any direction, without splitting.</p>

<p><b>Frieze</b> - A long narrow horizontal panel or band with decoration on it.</p>

<p><b>Keystone</b> - The centre stone in an arch which holds it together.</p>

<p><b>Marble</b> - A rock that is commonly used as a building material and in sculpture. It often has a white or pale appearance. Some common types are Carrera and Brescia.</p>

<p><b>Mosaic</b> - A decorative pattern or image created using small, coloured pieces of tile, glass, or stone.</p>

<p><b>Parapet</b> - A low wall at the edge of a roof, balcony etc.</p>

<p><b>Pilaster</b> - A rectangular column that looks like it’s stuck onto the wall.</p>

<p><b>Pediment</b> - A flat triangular shape at the top and front of a building, usually held up by columns.</p>

<p><b>Plasterwork</b> - Decoration on a surface made from a smooth paste called plaster.</p>

<p><b>Skirting</b> - A moulded panel covering the join between the wall and the floor.</p>

<p><b>Spandrels</b> - The space between the curved part of two arches.</p>

<p><b>Stained</b> glass - Ornate, colourful and decorative glass panels which often illustrate a story or presents a version of historic events. It is constructed by joining together varying shapes of coloured glass using strips of lead.</p>

    `
  },
  credits:{
    header: 'Credits',
    component:Credits
  }
}

export default OverlayModel