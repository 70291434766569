import {
  useState
} from 'react'

import {
  AnimatePresence,
  motion
} from 'framer-motion'

import {
  FaArrowDown,
  FaXmark
} from 'react-icons/fa6'

const variants = {
  open: {
    height: 'auto',
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2
    }
  },
  closed: {
    height: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
}

const additionalMenu = [
  {
    header: "How to use this resource",
    overlay:"how_to"
  },
  {
    header:"About",
    overlay:"about"
  },
  {
    header:"Resources",
    overlay:"resources"
  },
  {
    header:"Glossary",
    overlay:"glossary"
  },
  {
    header:"Credits",
    overlay:"credits"
  }
]

const BurgerMenu = ({
  sections,
  selectSection,
  selectOverlay
}) => {

  const [isOpen, setIsOpen] = useState(false)

  const _selectSection = (section) => {
    setIsOpen(false)
    selectSection(section)
  }

  return (
    <AnimatePresence>
      <motion.div
        key={`burger-menu`}
        variants={variants}
        initial="closed"
        animate="open"
        exit="closed"
        className="burger-menu">
          {
            isOpen ?
            <FaXmark
              key={`burger-menu-icon-close`}
              onClick={() => setIsOpen(!isOpen)}
              className="burger-menu-icon-close"/> :
            <FaArrowDown
              key={`burger-menu-icon`}
              onClick={() => setIsOpen(!isOpen)}
              className="burger-menu-icon"/> 
          }
            {
              isOpen && 
              <motion.div
                key={`burger-menu-items`}
                className='burger-menu-items'
                variants={variants}>
                <motion.div
                    key={`burger-menu-rooms-col`}
                    className="burger-menu-rooms-col burger-menu-col">
                  <motion.div
                    key={`burger-menu-rooms-header`}
                    className="burger-menu-rooms-header">
                      <h1>Rooms</h1>
                  </motion.div>
                  {
                    sections.map((section, i) => {
                      return (
                      <AnimatePresence
                        key={`burger-menu-presence-${i}`}
                        >
                        <motion.div
                          key={`burger-menu-${i}`}
                          variants={{
                            open: {
                              y: 0,
                              opacity: 1,
                              transition: {
                                y: { stiffness: 1000, velocity: -100 }
                              }
                            },
                            closed: {
                              y: 50,
                              opacity: 0,
                              transition: {
                                y: { stiffness: 1000 }
                              }
                            }
                          }}
                          exit={{
                            y: 50,
                            opacity: 0,
                            transition: {
                              y: { stiffness: 1000 }
                            }
                          }}
                          className="burger-menu-item"
                          onClick={() => _selectSection(section)}>
                            <div 
                              key={`burger-menu-square-${i}`}
                              style={{
                                backgroundColor: section.colour
                              }}
                              className="burger-menu-item-square"></div>
                            <h1 key={`burger-menu-name-${i}`}>{section.header}</h1>
                        </motion.div>
                      </AnimatePresence>
                      )
                    })
                  }
                </motion.div>
                <motion.div
                  key={`burger-menu-additional-col`}
                  className="burger-menu-additional-col burger-menu-col">
                  <motion.div
                    key={`burger-menu-additional-header`}
                    className="burger-menu-additional-header">
                      <h1>More</h1>
                  </motion.div>
                    {
                      additionalMenu.map((menuItem, i) => {
                        return (
                        <AnimatePresence
                          key={`burger-menu-presence-${i}`}
                          >
                          <motion.div
                            key={`burger-menu-${i}`}
                            variants={{
                              open: {
                                y: 0,
                                opacity: 1,
                                transition: {
                                  y: { stiffness: 1000, velocity: -100 }
                                }
                              },
                              closed: {
                                y: 50,
                                opacity: 0,
                                transition: {
                                  y: { stiffness: 1000 }
                                }
                              }
                            }}
                            exit={{
                              y: 50,
                              opacity: 0,
                              transition: {
                                y: { stiffness: 1000 }
                              }
                            }}
                            className="burger-menu-item"
                            onClick={() => selectOverlay(menuItem)}>
                              <div 
                                key={`burger-menu-square-${i}`}
                                style={{
                                  backgroundColor: "#d5d5d5"
                                }}
                                className="burger-menu-item-square"></div>
                              <h1 key={`burger-menu-name-${i}`}>{menuItem.header}</h1>
                          </motion.div>
                        </AnimatePresence>
                        )
                      })
                    }
                </motion.div>
              </motion.div>
          }
      </motion.div>
    </AnimatePresence>
  )

}

export default BurgerMenu