import { useState } from 'react'

import {
  AnimatePresence,
  motion
} from 'framer-motion'

import IntroModel from '../models/IntroModel'
import Loader from './Loader'
import LoaderBG from '../assets/loader_bg.jpg'
import { useEffect } from 'react'

const LoadingScreen = ({
  frames,
  currFrame,
  exitLoadingScreen,
  openOverlay
}) => {

  const [didYouKnow, setDidYouKnow] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const getRandomDidYouKnow = () => {
    const index = Math.floor(Math.random() * IntroModel.didYouKnow.length)
    return IntroModel.didYouKnow[index]
  }

  useEffect(() => {
    setDidYouKnow(getRandomDidYouKnow())
  }, [])

  useEffect(() => {
    console.log('frames', frames)
    console.log('currFrame', currFrame)
    if (currFrame + 1 === frames) {
      setIsLoading(false)
    }
  }, [currFrame])

  return (
    <motion.div
      className="loading-screen">
      <h1>
        {IntroModel.header}
        <div 
          onClick={() => openOverlay('how_to')}
          className="how-to">
          how to use this resource
        </div>
      </h1>
      <h2>{IntroModel.subheader}</h2>
      <motion.div 
        key={`shutter-left`}
        className="shutter-left">
      </motion.div>
      <motion.div
        key={`shutter-right`}
        className="shutter-right">
      </motion.div>
      <img src="./assets/loader.svg"/>
      {
        isLoading ?
        <div className="loading-wrapper">
          <Loader/>
          {
            <div className="loading-text">
              {`${(currFrame / frames * 100).toFixed(0)}%`}
            </div>
          }
        </div> :
        <motion.button
          key={`enter-button`}
          initial={{
            y:50,
            opacity:0
          }}
          animate={{
            y:0,
            opacity:1,
            transition:{
              duration: 0.5,
              delay: 0.5
            }
          }}
          className="enter-button"
          onClick={() => exitLoadingScreen()}>
            Enter Glasgow City Chambers
        </motion.button>
      }
      <motion.div
        key={`text-did-you-know`}
        initial={{
          y:-50,
          opacity:0
        }}
        animate={{
          y:0,
          opacity:1,
          transition:{
            duration: 0.5,
            delay: 0.5
          }
        }}
        exit={{
          y:-50,
          opacity:0
        }}
        transition={{
          opacity: {
            duration: 0.5
          }
        }}
        className="did-you-know">
        {didYouKnow}
      </motion.div>
    </motion.div>
  )

}

export default LoadingScreen