import GC_HandsUp from "../assets/gc_hands_up.png"
import GC_PointRight from "../assets/gc_point_right.png"
import GC_HandsDown from "../assets/gc_hands_down.png"
import GC_PointForward from "../assets/gc_point_forward.png"
import GC_914 from "../assets/0914.png"
import GC_432 from "../assets/0432.png"
import GC_523 from "../assets/0523.png"
import GC_748 from "../assets/0748.png"
import GC1286 from "../assets/1286.png"
import GC1154 from "../assets/1154.png"
import GC228 from "../assets/0228.png"
import GC862 from "../assets/0862.png"
import GC36 from "../assets/0036.png"
import GC431 from "../assets/0431.png"
import GC1497 from "../assets/1497.png"
import GC1421 from "../assets/1421.png"
import GC1303 from "../assets/1303.png"
import GC1583 from "../assets/1583.png"
import GC384 from "../assets/0384.png"

const Timeline = {
    intro:{
      colour:'#c8b7da',
      header:"Glasgow City Chambers",
      subheader:"The City Chambers is the headquarters of Glasgow City Council and one of the city's most prestigious buildings.",
      description:`It is where famous visitors are welcomed to the city, and big events also take place here, such as civic receptions to recognise achievements, cultural festivals and award ceremonies. Glasgow City Chambers is a Victorian building - meaning it was built during the reign of Queen Victoria (1837 - 1901).  It was designed by William Young, an architect from Paisley, and opened in 1888.`,
      frameCount:1524,
      textBoxes:[
        {
          frame:[100, 200],
          text:"The building shows the skill of Glasgow’s Victorian craftspeople, and includes many different materials such as stone, marble, wood and gold, sourced from all over the world. The Victorian period was one of amazing progress. Cities grew and the lives of ordinary people were improved through things like the Public Health Act, the Education Act, and the establishment of more public services like libraries, hospitals and schools. The City Chambers played a vital role in this, and was designed to reflect the wealth of Glasgow’s merchants and businessmen."
        },
        {
          frame:[300, 400],
          text:"However, it is important to recognise that Glasgow played a significant part in the transatlantic slave trade and the expansion of the British Empire. Many of Glasgow’s wealthy merchants made their fortunes through the slave trade and a lot of its buildings were funded from the proceeds. Even though the slave trade had ended by 1888, the British Empire was still able to use its power and influence to take resources from other countries around the world. You can actually visit the City Chambers too! It is open to the public via free daily tours, so you can explore and see where all the big decisions are made. Check out their website for more information. Not to worry if you can’t visit in person though, as you can discover it virtually here!"
        }
      ],
      greenCoat:[
        {
          frame:[1300,1400],
          image:GC_914,
          text:"There are many different styles of architecture in Glasgow and Glasgow City Chambers is an example of the Classical style. Another example of this type of building is the Gallery of Modern Art on Royal Exchange Square - the building with the statue of the Duke of Wellington that always has a cone on his head!"
        }
      ],
      stops:[
        {
          frame:[650,1050],
          callouts:[
            {
              title:"Banqueting Hall",
              text:'',
              x:663,
              y:483,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[701,1050],
          callouts:[
            {
              title:"Council Chamber",
              text:'',
              x:817,
              y:621,
              position:'top-right'
            }
          ]
        },
        {
          frame:[751,1050],
          callouts:[
            {
              title:"Loggia",
              text:'',
              x:836,
              y:872,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[801,1050],
          callouts:[
            {
              title:"Lord Provost's Office",
              text:'',
              x:1052,
              y:712,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[851,1050],
          callouts:[
            {
              title:"Councillors' Corridor",
              text:'',
              x:1124,
              y:590,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[901,1050],
          callouts:[
            {
              title:"Civic Staircase",
              text:'',
              x:718,
              y:756,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[951,1050],
          callouts:[
            {
              title:"Working Staircase",
              text:'',
              x:956,
              y:828,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[1001,1050],
          callouts:[
            {
              title:"Portrait Gallery",
              text:'',
              x:960,
              y:385,
              position:'top-right'
            }
          ]
        },
        {
          frame:[1175,1259],
          callouts:[
            /*{
              title:"Doorways",
              text:'',
              x:871,
              y:1006,
              position:'top-left'
            },*/
            {
              title:"Keystone",
              text:'',
              x:975,
              y:926,
              position:'bottom-right'
            },
            /*{
              title:"Spandrels",
              text:'',
              x:1105,
              y:957,
              position:'bottom-right'
            }*/
            {
              title:"Entablature",
              text:'',
              x:700,
              y:686,
              position:'bottom-left'
            },
            {
              title:"Pediment",
              text:'',
              x:975,
              y:573,
              position:'top-left'
            },
            {
              title:"Corinthian columns",
              text:'',
              x:913,
              y:807,
              position:'top-right'
            },
            {
              title:"Balconies",
              text:'',
              x:700,
              y:857,
              position:'bottom-left'
            },
            {
              title:"Cornice",
              text:'',
              x:1147,
              y:738,
              position:'bottom-right'
            },
            {
              title:"Parapet",
              text:'',
              x:554,
              y:630,
              position:'bottom-left'
            },
            {
              title:"Ionic pilasters",
              text:'',
              x:962,
              y:383,
              position:'top-right'
            },
            {
              title:"Dome",
              text:'',
              x:978,
              y:86,
              position:'bottom-left'
            },
            {
              title:"Finial",
              text:'',
              x:1163,
              y:432,
              position:'bottom-right'
            }
          ]
        }
      ]
    },
    loggia:{
      colour:'#e3bac7',
      header:'Loggia',
      subheader:"This is the main entrance to the City Chambers.",
      description:"The word Loggia comes from the Italian word for lodge. On the Loggia floor there is a mosaic of Glasgow’s Coat of Arms with the city motto ‘Let Glasgow Flourish’. Two large doors in here have caryatides on either side - these are stone columns carved in the shape of women. They represent Wisdom, Strength, Purity and Honour.",
      frameCount: 1000,
      greenCoat:[
        {
          frame:[760, 860],
          image:GC_432,
          text:"There are more than 1.5 million tiles used in the mosaics on the vaulted ceilings and domes of the entrance and these were all laid by hand!"
        }
      ],
      stops:[
        {
          frame:[201,251],
          callouts:[
            {
              title:"Glasgow Coat of Arms",
              text:"The Coat of Arms has been used since 1866. It shows items related to the life and legend of St Mungo, who is said to be the founder of Glasgow.",
              x:960,
              y:267,
              position:'top-right'
            },
            {
              title:"Here is the tree that never grew",
              text:"",
              x:964,
              y:522,
              position:'bottom-left'
            },
            {
              title:"Here is the bird that never flew",
              text:"",
              x:957,
              y:455,
              position:'top-left'
            },
            {
              title:"Here is the fish that never swam",
              text:"",
              x:960,
              y:651,
              position:'bottom-left'
            },
            {
              title:"Here is the bell that never rang",
              text:"",
              x:1028,
              y:594,
              position:'bottom-right'
            },
            {
              title:"Let Glasgow Flourish",
              text:"This was registered as the official motto of Glasgow in 1866.",
              x:964,
              y:850,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[449,499],
          callouts:[
            {
              title:"Lamp of Remembrance",
              text:"This was presented to the Chambers by the ex-service nurses of Glasgow in memory of those who died in both World Wars.",
              x:1054,
              y:314,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[697,747],
          callouts:[
            {
              title:"Ornate ceiling domes",
              text:"Covered in mosaic tiles to match the pattern of the tiled floor design.",
              x:908,
              y:99,
              position:'bottom-right'
            }
          ]
        }
      ]
    },
    working_staircase:{
      colour:'#efcc94',
      header:'Working Staircase',
      subheader:"This is the main staircase for Councillors and staff at the City Chambers.",
      description:"This staircase leads directly to the Council Hall and Council Chamber. Light streams in through a stained glass dome.",
      frameCount: 650,
      greenCoat:[
        {
          frame:[10, 110],
          image:GC1286,
          text:"The Lucky Lion can be found on this staircase! It is made of white marble and is very similar in design to the lions that feature on the Cenotaph in George Square. People rub the Lucky Lion’s nose for good luck when they pass it."
        },
        {
          frame:[220, 320],
          image:GC1154,
          text:"On this staircase you can find graffiti left by soldiers during both World War One and World War Two! You can see names and dates scratched into the walls!"
        }
      ],
      stops:[
        {
          frame:[148,198],
          callouts:[
            {
              title:"Stained glass dome",
              text:"",
              x:950,
              y:172,
              position:'bottom-right'
            },
            {
              title:"Ornamental plasterwork ceiling",
              text:"",
              x:780,
              y:806,
              position:'top-left'
            }
          ]
        },
        {
          frame:[388,438],
          callouts:[
            {
              title:"Lucky Lion",
              text:"",
              x:991,
              y:458,
              position:'top-right'
            }
          ]
        },
        {
          frame:[594,640],
          callouts:[
            {
              title:"Alabaster wall panels",
              text:"",
              x:912,
              y:410,
              position:'bottom-right'
            },
            {
              title:"Freestone steps",
              text:"",
              x:773,
              y:908,
              position:'top-left'
            }
          ]
        }
      ]
    },
    council_chamber: {
      colour:'#e4aea2',
      header:'Council Chambers',
      subheader:'The Council Chamber is the room where Glasgow\'s City Council meet to debate and agree upon issues relating to the public services they provide.',
      description:'Each Councillor has their own designated seat and they sit within their political party group eg. Conservatives, Greens, Labour, Liberal Democrats, Scottish National Party. The Lord Provost meets here with the city\'s 85 Councillors every sixth Thursday at 11am. The Municipal Mace sits in a special holder placed in front of the Lord Provost during meetings. The Mace represents the people of Glasgow and if it is not there then important decisions cannot be made.',
      frameCount:725,
      greenCoat:[
        {
          frame:[10, 100],
          image:GC_HandsUp,
          text:'When the City Chambers was opened by Queen Victoria she gifted each Councillor a carnation. Some say this was to mask any bad smells! The Civic Officers still put flowers out in the Chamber for the last meeting before an election and the first meeting after an election.'
        },
        {
          frame:[600,673],
          image:GC_PointRight,
          text:'The Council Chamber gets locked by me when voting takes place to ensure that no one can influence decisions made. It is only unlocked when each Councillor has voted.'
        }
      ],
      stops:[
        {
          frame:[107,207],
          callouts:[
            {
              title:'Lord Provost\'s Chair',
              text:'A gift from Queen Victoria to the city at the formal opening of the building.',
              x:1058,
              y:429,
              position:'top-right'
            },
            {
              title:'Lord Provost\'s Desk',
              text:'Together with the Lord Provost\'s chair and Deputy\'s chair makes the Lord Provost\'s suite.',
              x:945,
              y:547,
              position:'bottom-right'
            },
            {
              title:'Traffic light system',
              text:' Councillors are told when they can speak during a Council meeting by a traffic light system. A green light means they can speak, amber means they need to wind up, and red means they have to stop!',
              x:970,
              y:827,
              position:'bottom-right',
              length:2
            },
            {
              title:'Municipal Mace Stand',
              text:'The Mace is carried ceremonially ahead of the Lord Provost on entering the chambers for full Council meetings. The Council rise to honour the mace which represents the people of Glasgow.',
              x:854,
              y:702,
              position:'bottom-left'
            },
            {
              title:'Venetian Windows',
              text:'Double glazed to block out the sound from George Square.',
              x:860,
              y:228,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[321,371],
          callouts:[
            {
              title:'Tynecastle Tapestry',
              text:'The walls are decorated with something called \'Tynecastle Tapestry\', which was designed to look like leather and has raised patterns on it  which are all decorated with real gold!',
              x:753,
              y:239,
              position:'bottom-left'
            },
            {
              title:'Copper panelling',
              text:'A copper repoussé of the Glasgow Coat of Arms. Repoussé is a way of decorating metal where a craftsperson hammers the back of the metal to raise up a pattern or picture on the front.',
              x:1018,
              y:598,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[485,535],
          callouts:[
            {
              title:'Spanish Mahogany wall panels',
              text:'Columns, chairs, door frames and chimney pieces are all made of this kind of wood too.',
              x:774,
              y:479,
              position:'bottom-left',
            },
            {
              title:'Public Gallery',
              text:'Council meetings are by law open to the public.',
              x:1109,
              y:258,
              position:'bottom-right'
            },
            {
              title:'The Bed Recess',
              text:'This is where officials sit to watch the Council meetings, named after sleeping areas in old tenements. You can see an original version of this in the Tenement House in Garnethill.',
              x:1204,
              y:673,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[674,724],
          callouts:[
            {
              title:'Councillors seating',
              text:'The red leather seats are decorated with Glasgow\s Coat of Arms.',
              x:803,
              y:994,              
              position:'top-right'
            },
            {
              title:'Distinguished Visitor and Press Gallery',
              text:'Where journalists can sit to report on what happens at Council meetings.',
              x:1089,
              y:505,
              position:'top-left'
            }
          ]
        }
      ]
    },
    councilors_corridor:{
      colour:"#c8b7da",
      header:'Councillors\' Corridor',
      subheader:"This is one of the main spaces where Councillors work.",
      description:"The Councillors Corridor is more than 30 metres long which is equal to the width of a football pitch! It is where councillors can meet privately to discuss issues that affect Glasgow and the people who live here. Each councillor represents a different area, or electoral ward, of the city. There are 23 electoral wards in Glasgow do you know which one you live in?",
      frameCount: 910,
      greenCoat:[
        {
          frame:[720, 820],
          image:GC1421,
          text:"Any adult who lives in Glasgow can become a local councillor - they just have to be interested in helping people and understanding how local government works."
        }
      ],
      stops:[
        {
          frame:[368,418],
          callouts:[
            {
              title:"Mosaic tiled floor",
              text:"",
              x:718,
              y:915,
              position:'top-left'
            },
            {
              title:"Brown tiled skirting",
              text:"",
              x:1028,
              y:852,
              position:'bottom-right'
            },
            {
              title:"Bottle green tiled dado panel",
              text:"",
              x:1044,
              y:770,
              position:'top-right'
            },
            {
              title:"Yellow squared tiled wall panel",
              text:"",
              x:1044,
              y:476,
              position:'bottom-left'
            },
            {
              title:"Green acanthus leaf tile",
              text:"",
              x:1031,
              y:130,
              position:'bottom-right'
            },
            {
              title:"Roman garland relief tiled frieze",
              text:"",
              x:858,
              y:310,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[651,701],
          callouts:[
            {
              title:"Mosaic tiled ceiling domes",
              text:"",
              x:1098,
              y:703,
              position:'bottom-right'
            }
          ]
        }
      ]
    },
    lord_provost_office:{
      colour:'#c9e7ea',
      header:'Lord Provost\'s Office',
      subheader:"This is where the Lord Provost works.",
      description:"The main function of the Lord Provost is to Chair Council meetings and to represent the council on Civic and Ceremonial occasions, as well as welcoming Heads of State like Kings, Queens and Presidents, on visits to Glasgow. When not in use the Municipal Mace and the Chain of Office are kept in a small room off this office, called an ante room.",
      frameCount: 500,
      greenCoat:[
        {
          frame:[10, 110],
          image:GC36,
          text:"The Lord Provost doesn’t have an official residence so it became tradition to place a pair of lamp posts with the Coat of Arms outside their flat or house. Some of these lamps can still be seen today - have you seen any?"
        },
        {
          frame:[320,420],
          image:GC431,
          text:"The Lord Provost is the first citizen of Glasgow and they are the city’s official representative of the British Monarch. There has been a Lord Provost in Glasgow for over 500 years."
        }
      ],
      stops:[
        {
          frame:[251,301],
          callouts:[
            {
              title:"Lord Provost's Desk",
              text:"Where the Lord Provost works and has meetings.",
              x:986,
              y:801,
              position:'bottom-right'
            },
            {
              title:"Window to George Square",
              text:"",
              x:1052,
              y:371,
              position:'bottom-left'
            }
          ]
        }
      ]
    },
    mace:{
      colour:"#c9e7ea",
      header:"Municipal Mace",
      subheader:"This represents the people of Glasgow and has to be present at all City Council meetings.",
      description:"Glasgow's Municipal Mace is made from gold-plated silver and is heavy- it weighs about the same as a pug! It dates back to 1912, when it was presented to the Council by Archibald Philip Primrose, 5th Earl of Rosebery who was Prime Minister from 1894-1895. Part of the ritual of the Council's proceedings is that the Mace is carried by a Civic Officer when leading the Lord Provost into the Council Chamber to chair full Council meetings.",
      frameCount: 1150,
      greenCoat:[
        {
          frame:[10,95],
          image:GC1303,
          text:"On the Head of the Mace there is a Crown which is an exact copy of the Crown of Scotland, the oldest crown in Great Britain!"
        },
        {
          frame:[950,1050],
          image:GC1583,
          text:"The Clyde has always been important to Glasgow and shipbuilding used to be one of the city’s main industries. By the early 1900s one in five ships worldwide were built along the Clyde and the phrase “Clyde Built” came to be a mark of quality."
        }
      ],
      stops:[
        {
          frame:[101,151],
          callouts:[
            {
              title:"Figure representing learning",
              text:"",
              x:970,
              y:351,
              position:'bottom-right'
            },
            {
              title:"Arms of Glasgow the University of Glasgow",
              text:"The city’s oldest University and the fourth oldest in the English-speaking world.",
              x:975,
              y:915,
              position:'top-right'
            }
          ]
        },
        {
          frame:[205,255],
          callouts:[
            {
              title:"Figure representing an artisan",
              text:"An artisan is a skilled craft worker who makes things by hand.",
              x:945,
              y:337,
              position:'bottom-right'
            },
            {
              title:"Arms of the Trades House",
              text:"",
              x:999,
              y:451,
              position:"bottom-left"
            },
            {
              title:"Arms of the Merchant House",
              text:"",
              x:955,
              y:917,
              position:'top-right'
            }
          ]
        },
        {
          frame:[306,356],
          callouts:[
            {
              title:"Arms of the The Earl of Rosebery",
              text:"",
              x:964,
              y:489,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[406,456],
          callouts:[
            {
              title:"Figure representing navigation",
              text:"Shipping and trading were one of the main industries in Glasgow’s Victorian age.",
              x:958,
              y:338,
              position:'bottom-right'
            },
            {
              title:"Arms of The Clyde Navigation Trust",
              text:"",
              x:951,
              y:906,
              position:'top-left'
            }
          ]
        },
        {
          frame:[506,556],
          callouts:[
            {
              title:"Arms of Scotland",
              text:"",
              x:976,
              y:483,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[606,656],
          callouts:[
            {
              title:"Figure representing St. Mungo",
              text:"Patron Saint of Glasgow",
              x:959,
              y:360,
              position:'bottom-right'
            },
            {
              title:"Arms of The Glasgow Cathedral",
              text:"",
              x:965,
              y:906,
              position:'top-left'
            }
          ]
        },
        {
          frame:[706,756],
          callouts:[
            {
              title:"Arms of Glasgow",
              text:"",
              x:973,
              y:493,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[890,940],
          callouts:[
            {
              title:"Head of the Mace",
              text:"Under the arches of the Crown are the Arms of Great Britain",
              x:985,
              y:600,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[1100,1145],
          callouts:[
            {
              title:"Decorative thistles",
              text:"The national flower of Scotland.",
              x:637,
              y:368,
              position:'bottom-right'
            }
          ]
        }
      ]
    },
    chain_of_office:{
      colour:"#e5afa4",
      header:"Chain of Office",
      subheader:"This is worn by the Lord Provost when on official duties.",
      description:"The Chain of Office is made of solid gold and features a large pendant which has the Glasgow Coat of Arms on one side and a figure representing Justice on the other. The Chain was first presented to Lord Provost James Lumsden in 1868. On the back of the Chain the names of the 54 Lord Provost’s since Lumsden are inscribed - this is done when a Lord Provost leaves office.",
      frameCount: 390,
      greenCoat:[
        {
          frame:[10, 110],
          image:GC1497,
          text:"One of my jobs as a Civic Officer is to look after the Chain of Office and the Mace to make sure they’re kept safe when they’re on official duties."
        }
      ],
      stops:[
        {
          frame:[120,170],
          callouts:[
            {
              title:"Pendant",
              text:"With Glasgow’s Coat of Arms on one side and a figure representing Justice on the other.",
              x:923,
              y:509,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[220,270],
          callouts:[
            {
              title:"Thistles",
              text:"The national flower of Scotland.",
              x:827,
              y:368,
              position:'top-left'
            },
            {
              title:"Celtic knot",
              text:"This represents a bond that cannot be broken.",
              x:1135,
              y:730,
              position:'bottom-right'
            }
          ]
        }
      ]
    },
    portrait_gallery: {
      colour:'#b3d6bb',
      header:'Portrait Gallery',
      subheader:"The Portrait Gallery is an open space on the third floor of the building full of portraits of former Lord Provosts of Glasgow.",
      description:"Many of the portraits in the gallery were painted by some of The Glasgow Boys, the artists who painted the murals in the Banqueting Hall. It features a large stained glass oval dome that helps flood the Working staircase below with natural light. Although the wall panels look like the alabaster on the staircases they are actually just painted wooden panels! There is also more Tynecastle Tapestry covering the walls and more ornate plasterwork on the ceiling, meaning lots more gold detailing too! The names and dates of office of Lord Provosts in the gallery are detailed on the frames of many artworks and here in the resource.",
      frameCount: 1560,
      greenCoat:[],
      stops:[
        {
          frame:[101,151],
          callouts:[
            {
              title:'Stained glass dome',
              text:'',
              x:970,
              y:140,
              position:'bottom-right'
            },
            {
              title:'Oval baulstrade',
              text:"This large opening in the floor lets lots of light flood from the stained glass dome to the working staircase below.",
              x:977,
              y:793,
              position:'top-left'  
            }
          ]
        },
        {
          frame:[265,315],
          callouts:[
            {
              title:'Dame Jean Roberts',
              text:"(1960-63) - The first ever female Lord Provost of Glasgow, she accompanied Queen Elizabeth II on a tour of the city in 1961. Her portrait was painted by David A. Donaldson.",
              x:940,
              y:730,
              position:'bottom-right',
            }
          ]
        },
        {
          frame:[407,457],
          callouts:[
            {
              title:'John Johnston',
              text:"(1965-69)",
              x:606,
              y:486,
              position:'top-right',
            },
            {
              title:'Sir Peter Meldrum',
              text:"(1963-65)",
              x:616,
              y:715,
              position:'bottom-right',
            },
            {
              title:'Sir Donald Liddle',
              text:"(1969-72)",
              x:1394,
              y:715,
              position:'bottom-left',
            }
          ]
        },
        {
          frame:[553,603],
          callouts:[
            {
              title:'John Mains',
              text:"(May-July 1972)",
              x:433,
              y:608,
              position:'bottom-right'
            },
            {
              title:'Sir William Gray',
              text:"(1972-75)",
              x:1197,
              y:613,
              position:'top-left'
            },
            {
              title:"David Hodge",
              text:"(1977-80)",
              x:1553,
              y:618,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[704,754],
          callouts:[
            {
              title:"Michael Kelly",
              text:"(1980-84)",
              x:663,
              y:736,
              position:'top-left'
            },
            {
              title:"Robert Gray",
              text:"(1984-86)",
              x:1053,
              y:760,
              position:'bottom-left'
            },
            {
              title:"Susan Baird",
              text:"(1988-92)",
              x:1561,
              y:776,
              position:'top-left'
            }
          ]
        },
        {
          frame:[853,903],
          callouts:[
            {
              title:"Robert Innes",
              text:"(1992-94)",
              x:407,
              y:736,
              position:'bottom-right'
            },
            {
              title:'James Shields',
              text:"(1994-95)",
              x:950,
              y:720,
              position:'top-left'
            },
            {
              title:'Thomas Dingwall',
              text:"(1995-96)",
              x:1433,
              y:739,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[1008,1058],
          callouts:[
            {
              title:'Patrick Lally',
              text:"(1996-99) - Credited as a driving force behind some of Glasgow’s civic successes: Glasgow Garden Festival (1988), Glasgow European City of Culture (1990) and European City of Architecture (1999). His portrait was painted by Peter Howson.",
              x:1030,
              y:650,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[1153,1203],
          callouts:[
            {
              title:'Alex Mosson',
              text:"(1999-03)",
              x:321,
              y:674,
              position:'bottom-right'
            },
            {
              title:'Elizabeth Cameron',
              text:"(2003-07)",
              x:740,
              y:669,
              position:'top-left'
            },
            {
              title:'Bob Winter',
              text:"(2007-12)",
              x:1185,
              y:664,
              position:'bottom-left'
            },
            {
              title:'Sadie Docherty',
              text:"(2012-17)",
              x:1599,
              y:664,
              position:'top-left'
            }
          ]
        },
        {
          frame:[1274,1324],
          callouts:[
            {
              title:"Andrew Galbraith",
              text:"(1857-60)",
              x:250,
              y:732,
              position:'top-right'
            },
            {
              title:"Sir William Collins",
              text:"(1877-80)",
              x:595,
              y:741,
              position:'bottom-left'
            },
            {
              title:'John Ure',
              text:"(1880-83)",
              x:1047,
              y:758,
              position:'top-left'
            },
            {
              title:'Sir William McOnie',
              text:"(1883-86)",
              x:1583,
              y:774,
              position:'top-left'
            }
          ]
        },
        {
          frame:[1398,1448],
          callouts:[
            {
              title:"Sir John Muir",
              text:"(1889-92)",
              x:750,
              y:790,
              position:'top-left'
            },
            {
              title:'Sir David Richmond',
              text:"(1896-99) - While Lord Provost he oversaw the building of the People's Palace, laid the foundation stone of Kelvingrove Art Gallery and Museum, and was greatly involved in the expansion of electricity through the city as well as the building of public baths and fire stations. His portrait was painted by John Singer Sargent.",
              x:1187,
              y:787,
              position:'bottom-left'
            }
          ]
        },
      ]
    },
    civic_marble_staircase:{
      colour:'#accbed',
      header:'Civic Staircase',
      subheader:"This is the main staircase for guests visiting the City Chambers.",
      description:"It is designed to be a very grand and impressive space. The staircase is mainly made from marble and alabaster, which are materials that have been used since Ancient Egypt!",
      frameCount: 500,
      greenCoat:[
        {
          frame:[10, 100],
          image:GC_523,
          text:"It is Western Europe’s largest marble staircase, with 110 steps over three levels. It is made from Carrara marble, which is very popular and comes from Italy."
        },
        {
          frame:[220, 320],
          image:GC_748,
          text:"Legend has it that St Mungo founded Glasgow in 543 but it was in around 1175 that the city was awarded its official City Charter. The city still celebrates that year as its official birthday, so in 2025 it will be 850 years old!"
        }
      ],
      stops:[
        {
          frame:[152,202],
          callouts:[
            {
              title:"Carrara marble steps",
              text:"",
              x:1257,
              y:914,
              position:'top-right'
            },
            {
              title:"Brescia marble arches",
              text:"",
              x:896,
              y:457,
              position:'top-left'
            },
            {
              title:"Brescia marble columns",
              text:"",
              x:741,
              y:714,
              position:'top-right'
            }
          ]
        },
        {
          frame:[453,495],
          callouts:[
            {
              title:"Alabaster columns",
              text:"",
              x:829,
              y:729,
              position:'bottom-left'
            },
            {
              title:"Alabaster wall panel",
              text:"",
              x:1050,
              y:808,
              position:'bottom-right'
            },
            {
              title:"Ornate plasterwork ceiling",
              text:"",
              x:892,
              y:262,
              position:'bottom-left'
            }
          ]
        }
      ]
    },
    banqueting_hall:{
      colour:'#cae0b3',
      header:'Banqueting Hall',
      subheader:"The Banqueting Hall is a very large, ornate room that is used for important ceremonies and big celebrations. It is 33.5 metres long - that’s as long as 3 buses!",
      description:"Kings, Queens, Presidents and celebrities from all over the world have attended events held here. As well as lots of beautiful murals (wall paintings) depicting Glasgow’s history and achievements as a city from mediaeval to Victorian times, this room features three very large electroliers, or ceiling lamps, which symbolise Victorian Glasgow’s innovation and style. The wall panels feature artworks that illustrate the history and progress of Glasgow from the granting of the City Charter to the Victorian Age. These murals were painted by a group of artists known as The Glasgow Boys. These artists liked to paint things from nature and their work often depicted Scottish icons and culture. Many of their other works can be seen at Kelvingrove Art Gallery and Museum.",
      frameCount: 1500,
      greenCoat:[
        {
          frame:[10, 110],
          image:GC384,
          text:"The interior of the Banqueting Hall was decorated by McCulloch and Co., under the supervision of William Leiper, the architect of Templeton Carpet Factory, which you can find on Glasgow Green."
        },
        {
          frame:[1348, 1448],
          image:GC862,
          text:"Glasgow City Chambers was one of the very first public buildings in Glasgow to be lit by electricity. The electric light bulb was only invented in 1881 - the same year the building was designed."
        }
      ],
      stops:[
        {
          frame:[173,223],
          callouts:[
            {
              title:"Granting of Glasgow’s Charter by William the Lion",
              text:"Painted by George Henry, this mural depicts the granting of the City Charter by King William the Lion, King of the Scots around 1175.",
              x:857,
              y:577,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[274,324],
          callouts:[
            {
              title:"The Legend of Glasgow’s City Arms",
              text:"Painted by Alexander Roche, this mural depicts St Mungo finding the lost ring of Languoreth, Queen of Strathclyde, in the mouth of a salmon. This was one of St Mungo’s miracles and is why there’s a fish and ring on Glasgow’s Coat of Arms.",
              x:966,
              y:525,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[384,434],
          callouts:[
            {
              title:"The Glasgow Fair",
              text:"Painted by Edward Walton, this mural depicts the Glasgow Fair, where people bought and sold animals. It has since developed into a holiday for everyone and has been celebrated for over 800 years.",
              x:902,
              y:483,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[494,544],
          callouts:[
            {
              title:"Shipbuilding",
              text:"Painted by Sir John Lavery, this mural represents one of Glasgow’s main heavy industries at the time the Chambers were built. William Burrell was a big supporter of this artist and many of his other works can be seen at The Burrell Collection in Glasgow.",
              x:890,
              y:510,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[602,652],
          callouts:[
            {
              title:"Glasgow Progress",
              text:"The panels along the top of the wall show how the city has changed since prehistoric times to the Victorian era.",
              x:1057,
              y:220,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[703,753],
          callouts:[
            {
              title:"River Murals",
              text:"Painted by William Findlay, the four murals above the Hall’s main entrance doors depict Scotland’s four main rivers, shown left to right: the Tay, the Forth, the Clyde, and the Tweed. The artist was inspired by the poetry and history associated with these rivers.",
              x:1002,
              y:491,
              position:'bottom-right'
            },
            {
              title:"Minstrel’s Gallery",
              text:"This space is used for musicians, historically called minstrels, to perform.",
              x:788,
              y:198,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[861,911],
          callouts:[
            {
              title:"The Glasgow to Come",
              text:"Painted by Dr. Forrester Wilson, this mural shows the hopes and dreams of the people who built the City Chambers for Glasgow’s future.",
              x:698,
              y:517,
              position:'bottom-left'
            }
          ]
        },
        {
          frame:[987,1320],
          callouts:[
            {
              title:"Virtues",
              text:" Painted by Alexander Walker, this series of murals symbolise positive qualities for Victorian Glaswegians, such as modesty, faith, hope, mercy, justice and fortitude.",
              x:1055,
              y:594,
              position:'bottom-right'
            }
          ]
        },
        {
          frame:[1449,1499],
          callouts:[
            {
              title:"Electroliers",
              text:"Three large electric chandeliers which were originally powered by a generator in the basement.",
              x:1064,
              y:166,
              position:'bottom-right'
            },
            {
              title:"Guilded ceiling plasterwork",
              text:"This is made with real gold leaf!",
              x:878,
              y:306,
              position:'bottom-left'
            },
            {
              title:"Central stained glass panel",
              text:"A celebration of the Diamond Jubilee of Queen Victoria set in grey leaded Venetian glass.",
              x:904,
              y:666,
              position:'bottom-left'
            }
          ]
        }
      ]
    }
  }

  export default Timeline